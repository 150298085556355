import React, { FunctionComponent } from 'react';

type Props = {
  /**
   * @note Only handling horizontal directions
   * @default left
   * */
  direction?: 'left' | 'right';
  color?;
  rotate?;
} & React.ComponentPropsWithoutRef<'svg'>;

const SimpleArrow: FunctionComponent<Props> = ({
  direction = 'right',
  color = 'black',
  rotate = 0,
  strokeWidth = 1.25,
  ...props
}) => {
  const style = {
    ...(props.style ? props.style : {}),
    transform: `scale(${direction === 'right' ? 1 : -1}) rotate(${rotate}deg) translate3d(0, 0, 0)`,
  };

  return (
    <svg
      width="6"
      height="11"
      viewBox="0 0 6 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      style={style}>
      <path d="M0.927246 1L5.09215 5.73684L0.927246 10" stroke={color} strokeWidth={strokeWidth} />
    </svg>
  );
};

export default React.memo(SimpleArrow);
