import React, { FunctionComponent } from 'react';

type Props = {
  color?;
}

const ArrowRight:FunctionComponent<Props> = ({ color = 'black' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="6" fill="none" viewBox="0 0 18 6">
      <path
        fill={color}
        d="M14.281 6l-.756-.743L15.821 3 13.525.743 14.281 0l3.052 3-3.051 3z"></path>
      <path fill={color} d="M16.577 2.48H0v1.052h16.577V2.48z"></path>
    </svg>
  );
};

export default React.memo(ArrowRight);
