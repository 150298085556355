import { PortableText as PortableTextComponent } from '@portabletext/react';
import createImageUrlBuilder from '@sanity/image-url';
import { definePreview } from 'next-sanity/preview';
import { ImageUrlBuilder } from 'next-sanity-image';

import * as config from './config';

// import { projectId, dataset } from '@libs/sanity.client';
// import { sanityConfig } from './config';

const imageBuilder = createImageUrlBuilder(config.sanityConfig);

const urlForImage = (source: string): ImageUrlBuilder => {
  return source ? imageBuilder.image(source).auto('format').fit('max') : null;
};

// Todo - check new syntax
// const PortableText = PortableTextComponent({
//   ...config.sanityConfig,
//   // Serializers passed to @sanity/block-content-to-react
//   // (https://github.com/sanity-io/block-content-to-react)
//   serializers: {},
// });

const PortableText = (props) => <PortableTextComponent {...props} />;

const usePreviewSubscription = definePreview(config.sanityConfig);

export { urlForImage, PortableText, usePreviewSubscription };
//
