import CtaLink from '@bits/CtaLink';
import React, { FunctionComponent } from 'react';

import SimpleArrow from '../icons/SimpleArrow';
import styles from './index.module.scss';

type Props = {
  cta?;
  color?: string;
  text?: string;
  reverced?: boolean;
  classes?;
  onClick?: () => void;
};

const SimpleArrowButton: FunctionComponent<Props> = ({
  cta,
  color = 'black',
  onClick = null,
  text = 'text',
  reverced = false,
  classes = {},
}) => {
  if (cta) {
    const c =
      cta._type == 'ctaCar'
        ? {
            title: cta.car.title,
            route: { slug: cta.car.content.carInfo.slug },
          }
        : cta._type == 'cta' && cta.route?._type === 'car'
        ? {
            title: cta.route.content.carInfo.title,
            route: { slug: cta.route.content.carInfo.slug.current },
          }
        : cta;

    return (
      <>
        {cta?.title && (
          <span
            className={`${Object.keys(classes).length ? classes : ''} ${styles.root} ${
              reverced ? styles['_reverced'] : ''
            }`}
            style={{ color }}>
            <CtaLink cta={c} text={cta.title} /> <SimpleArrow color={color} />
          </span>
        )}
      </>
    );
  }

  if (onClick) {
    return (
      <span
        className={`${styles['root']} ${reverced ? styles['_reverced'] : ''}`}
        style={{ color }}
        onClick={onClick}>
        <span>{text}</span> <SimpleArrow color={color} direction={reverced ? 'left' : 'right'} />
      </span>
    );
  }

  return <span>missing cta or onClick</span>;
};

export default React.memo(SimpleArrowButton);
