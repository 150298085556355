import CtaLink from '@bits/CtaLink';
import React from 'react';

import styles from './index.module.scss';

type Props = {
  cta?;
  outline?: boolean;
  /**
   * @deprecated
   * @warn Do not use. Use theme classes to set the colors.
   * @see _themes.scss
   */
  inverse?: boolean;
  isActive?: boolean;
  overrideText?: boolean;
  text?: string;
  onClick?: () => void;
  className?: string;
  icon?: React.ReactNode;
};

const SquareButton: React.FC<Props> = ({
  cta,
  text = null,
  onClick = null,
  overrideText = false,
  outline = false,
  isActive = false,
  className,
  icon,
}) => {
  const color = outline ? 'var(--theme-button-background)' : 'var(--theme-button-color)';
  const backgroundColor = outline ? 'transparent' : 'var(--theme-button-background)';
  return (
    <span
      style={{
        '--color': color,
        '--background-color': backgroundColor,
        '--hover-color': outline ? 'var(--theme-button-color)' : 'var(--theme-button-background)',
        '--background-hover-color': outline ? 'var(--theme-button-background)' : 'transparent',
        '--border': `1px solid var(--theme-button-background)`,
      }}
      className={`${styles.root} ${className ? className : ''} ${isActive ? styles.active : ''}`}
      onClick={onClick || null}>
      {cta ? (
        <CtaLink cta={cta} redirect={cta?.redirect} text={text} overrideText={overrideText} />
      ) : (
        text
      )}
      {icon && icon}
    </span>
  );
};

export default React.memo(SquareButton);
