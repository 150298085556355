import styled from '@emotion/styled';
import React, { FunctionComponent } from 'react';

import styles from './index.module.scss';

type Props = {
  color?: string;
  text?: string;
  onClick?: () => void;
  active?: boolean;
  inactiveStyle;
  activeStyle;
  icon?;
};

const PillButton: FunctionComponent<Props> = ({
  text = null,
  activeStyle,
  inactiveStyle,
  onClick = null,
  active = false,
  icon = false,
}) => {
  const style = active ? activeStyle : inactiveStyle;

  const ButtonWithHover = styled('button')`
    ${style}
    &:hover {
      ${activeStyle}
    }
  `;

  return (
    <ButtonWithHover className={styles.root} onClick={onClick || null}>
      {icon ? icon : null}
      {text}
    </ButtonWithHover>
  );
};

export default React.memo(PillButton);
