import { getClient } from '@utils/sanity/sanity.server';
import imageUrlBuilder from '@sanity/image-url';

const builder = imageUrlBuilder(getClient());

export default function urlFor(source, forWebGL = true) {
  const url = forWebGL
    ? builder.image(source).format('webp').fit('max')
    : builder.image(source).auto('format').fit('max');
  return url;
}
