import Link from 'next/link';
import React, { FunctionComponent } from 'react';

import css from './index.module.scss';

type Props = {
  cta?;
  text?;
  title?;
  overrideText?;
  url?;
  redirect?;
  download?;
  className?: string;
};

const CTALink: FunctionComponent<Props> = ({
  cta,
  title,
  text = 'no slug',
  overrideText = false,
  url,
  download = false,
  className = '',
  redirect,
}) => {
  const t = overrideText ? text : cta?.title || title || text;

  if (redirect) {
    return (
      <Link href={redirect} passHref={true} legacyBehavior>
        <span className={css.root}>
          <a>{t || 'no slug'}</a>
        </span>
      </Link>
    );
  }

  if (url) {
    return (
      <Link href={`${url}?dl=${download}`} legacyBehavior>
        <button
          style={{
            color: 'white',
            backgroundColor: 'var(--theme-button-background)',
            border: `1px solid var(--theme-button-background)`,
            height: '45px',
          }}
          className={`${css.root} ${css['box']} ${className}`}>
          {t || title || 'no url'}
        </button>
      </Link>
    );
  }

  if (!cta?.route) {
    const newUrl =
      cta?.link || cta?.car?.content?.carInfo?.slug?.current || '/' + cta?.slug?.current;

    if (newUrl) {
      return (
        <Link href={newUrl} legacyBehavior>
          <span className={css.root}>
            <a>{t || 'no slug'}</a>
          </span>
        </Link>
      );
    }

    return <span className={css.root}>Needs an url</span>;
  }

  const newUrl = cta?.route?.slug?.current || cta?.route?.slug;

  return (
    <span className={css.root}>
      <Link href={'/' + newUrl} legacyBehavior>
        <a>{t || cta?.title || 'no slug'}</a>
      </Link>
    </span>
  );
};

export default CTALink;
