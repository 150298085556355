import CTALink from '@components/bits/CtaLink';
import SimpleBlockContent from '@components/bits/SimpleBlockContent';
import { basicInVewOptions } from '@utils/animations/fadeSettings';
import React, { FunctionComponent } from 'react';
import { useInView } from 'react-intersection-observer';

import styles from './index.module.scss';
type Props = {
  header;
  body;
  alignTextCenter;
  textAlignCenter;
  file;
};

const HeaderWithBody: FunctionComponent<Props> = ({
  header,
  body,
  alignTextCenter,
  textAlignCenter,
  file,
}) => {
  const { ref, inView } = useInView(basicInVewOptions);

  return (
    <div
      ref={ref}
      className={`
      ${styles.root}
      ${inView ? styles['in-view'] : ''}
      ${alignTextCenter ? styles.center : ''}
      ${textAlignCenter ? styles.centerText : ''} light`}>
      {header && <h4>{header}</h4>}
      <SimpleBlockContent data={body} />
      {file?.download?.asset?.url && (
        <CTALink
          className="BoxButton"
          url={file?.download?.asset?.url}
          title={file.buttonText}
          download={file?.download?.asset?.originalFilename}
        />
      )}
    </div>
  );
};

export default HeaderWithBody;
