/**
 * Server-side Sanity utilities. By having these in a separate file from the
 * utilities we use on the client side, we are able to tree-shake (remove)
 * code that is not used on the client side.
 */
import { createClient } from 'next-sanity';

import { editConfig, sanityConfig } from './config';

const config = sanityConfig;

export const sanityClient = createClient(config);
export const editClient = createClient(editConfig);

export const previewClient = createClient({
  ...config,
  useCdn: false,
  token: process.env.SANITY_API_TOKEN,
  withCredentials: true,
});

export const getClient = (preview?, editMode = false) => {
  if (editMode === true) {
    return editClient;
  }
  return sanityClient;
};
