import iub from '@utils/ImageUrlBuilder';
import { PortableText } from '@utils/sanity/sanity';
import React from 'react';
import { FunctionComponent } from 'react';
import ReactPlayer from 'react-player';

import styles from './index.module.scss';

const serializers = {
  types: {
    // eslint-disable-next-line react/display-name
    figure: (props): JSX.Element => {
      return <img alt="" className="block-image" src={iub(props.node).width(1000).url()} />;
    },
    // eslint-disable-next-line react/display-name
    videoUrl: (props): JSX.Element => {
      return (
        <div className={styles.video}>
          {props?.node?.videoURL && (
            <ReactPlayer
              muted={true}
              controls={true}
              width={'100%'}
              height={'100%'}
              url={props.node.videoURL}
            />
          )}
          {props?.node?.caption && <span> {props.node.caption} </span>}
        </div>
      );
    },
  },
  marks: {
    // eslint-disable-next-line react/display-name
    asterix: ({ children }) => {
      return <span className="asterix">{children}</span>;
    },
  },
};
type Props = {
  data;
};

const SimpleBlockContent: FunctionComponent<Props> = ({ data }) => {
  if (!data) return null;

  return <PortableText className={styles.root} value={data} components={serializers} />;
};

export default React.memo(SimpleBlockContent);
