'use client';
// export const NEXT_PUBLIC_GA_ID = process.env.NEXT_PUBLIC_GA_ID;
export const NEXT_PUBLIC_GA_4_ID = process.env.NEXT_PUBLIC_GA_4_ID;
export const GADS = process.env.GADS;
export const GTM_ID = process.env.GTM_ID;

export const PHONE_NUMBER_TRACKING_ID = 'c1BgCLWzgbMCEOfGu9wD';
export const EMAIL_TRACKING_ID = '4VSICMuYhrMCEOfGu9wD';
export const SOLTERRA_FORM_TRACKING_ID = 'yHwCCNm2irMCEOfGu9wD';
export const NEWSLETTER_TRACKING_ID = '3gEECNXdsbMCEOfGu9wD';
export const TEST_DRIVE_TRACKING_ID = '1CtKCJW0tbMCEOfGu9wD';
export const SHOW_THE_WAY_TO_DEALER_TRACKING_ID = 'IfcNCOOHjbMCEOfGu9wD';

type GTagEvent = {
  action: string;
  category: string;
  label: string;
  value: number | string;
};

declare global {
  interface Window {
    gtag: (type: string, id: string, o: unknown) => void;
  }
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: string): void => {
  if (typeof window !== 'undefined' && window.gtag) {
    window.gtag('config', NEXT_PUBLIC_GA_4_ID, {
      page_path: url,
    });
  }
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }: GTagEvent): void => {
  if (typeof window !== 'undefined' && window.gtag) {
    window.gtag('event', action, {
      event_category: category,
      event_label: label,
      value: value,
    });
  }
};

const eventGTM = (id) => {
  if (typeof window !== 'undefined' && window.gtag) {
    const combined = `'${GADS}/${id}'`;

    window.gtag('event', 'conversion', {
      send_to: combined,
    });
  }
};

export const triggerEmailEvent = (email: string): void => {
  // e.stopPropagation();
  // e.nativeEvent.stopImmediatePropagation();
  // console.log('trigger phone tracker');
  const gaEvent = {
    action: 'Email',
    category: 'interactions',
    label: email,
    value: '',
  };
  event(gaEvent);

  eventGTM(EMAIL_TRACKING_ID);
};
export const triggerPhoneEvent = (phone: string): void => {
  const gaEvent: GTagEvent = {
    action: 'Telefon',
    category: 'interactions',
    label: phone,
    value: '',
  };

  event(gaEvent);
  eventGTM(PHONE_NUMBER_TRACKING_ID);
};
export const triggerMapDirectionEvent = (map: string): void => {
  const gaEvent: GTagEvent = {
    action: 'Vis veien',
    category: 'Klikk',
    label: map,
    value: '',
  };
  event(gaEvent);
  eventGTM(SHOW_THE_WAY_TO_DEALER_TRACKING_ID);
};

export const triggerNewsletterEvent = (): void => {
  const gaEvent: GTagEvent = {
    action: 'Nyhetsbrev',
    category: 'Skjemainnsending',
    label: window.location.href,
    value: '',
  };
  event(gaEvent);
  eventGTM(NEWSLETTER_TRACKING_ID);
};
export const triggerTestDriveEvent = (bil: string, forhandler: string): void => {
  const gaEvent: GTagEvent = {
    action: bil,
    category: 'Bestilt prøvekjøring',
    label: forhandler,
    value: '',
  };

  // console.log('triggerTestDriveEvent');

  event(gaEvent);
  eventGTM(TEST_DRIVE_TRACKING_ID);
};
export const triggerEsuvInterestEvent = (): void => {
  const gaEvent: GTagEvent = {
    action: 'Nyhetsbrev Solterra',
    category: 'Skjemainnsending',
    label: window.location.href,
    value: '',
  };
  event(gaEvent);
  eventGTM(SOLTERRA_FORM_TRACKING_ID);
};
