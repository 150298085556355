import React, { FunctionComponent } from 'react';
type Props = {
  color?; onClick; classes?
}
const CloseXButton:FunctionComponent<Props> = ({ color = 'black', onClick, classes = '' }) => {
  return (
    <span className={classes} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="14"
        fill="none"
        viewBox="0 0 14 14">
        <path
          fill={color}
          d="M13.612 14a.39.39 0 01-.276-.114L.114.664a.39.39 0 01.55-.55l13.222 13.223a.39.39 0 01-.274.663z"></path>
        <path
          fill={color}
          d="M.39 14a.39.39 0 01-.275-.664L13.335.114a.39.39 0 01.55.55L.664 13.886A.387.387 0 01.389 14z"></path>
      </svg>
    </span>
  );
}

export default React.memo(CloseXButton);
