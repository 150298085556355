require('dotenv').config();

const dataset =
  process.env.NEXT_PUBLIC_VERCEL_ENV === 'development'
    ? 'develop'
    : process.env.NEXT_PUBLIC_SANITY_DATASET || 'production';

const sanityDevConfig = {
  dataset: dataset,
  projectId: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID,
  useCdn: false,
  apiVersion: '2021-05-25',
  token: process.env.SANITY_API_TOKEN,
};

const sanityProdConfig = {
  dataset: dataset,
  projectId: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID,
  useCdn: true,
  apiVersion: '2021-05-25',
};

export const sanityConfig =
  process.env.SANITY_USE_CDN === 'true' ? sanityProdConfig : sanityDevConfig;
export const editConfig = sanityDevConfig;
