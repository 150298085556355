const basicInVewOptions = {
  /* Optional options */
  threshold: 0.01,
  triggerOnce: true, // true
  rootMargin: '0px  0px 0px 0px',
  initialInView: false,
  delay: 100,
};
//
export { basicInVewOptions };
